import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import heroImage from '../assets/Hero-Image2.png'; // Update path if necessary
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const HeroSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleGenerateVideoClick = () => {
    navigate('/create-music');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={isMobile ? 'center' : 'flex-start'}
      justifyContent="center"
      height="90vh"
      style={{
        backgroundImage: `url(${heroImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: 'white',
        padding: isMobile ? '5% 2%' : '2% 7%',
        textAlign: isMobile ? 'center' : 'left',
      }}
    >
      <Typography
        variant={isMobile ? 'h3' : 'h1'}
        gutterBottom
        sx={{
          fontWeight: 'bold',
          mt: 8,
          mb: 2,
          background: 'linear-gradient(45deg, #849567, white)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Where AI Meets <br /> Content Creation
      </Typography>
      <Typography
        variant={isMobile ? 'h6' : 'h5'}
        gutterBottom
        sx={{
          mt: 3,
          fontSize: isMobile ? '1em' : '1.5em',
          fontFamily: 'revert',
        }}
      >
        Take your TikTok videos to the next level with customized mood-based <br />
        music generated by our AI to make your internet presence more about YOU.
      </Typography>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#A1C75E',
          padding: isMobile ? '2% 3%' : '1% 1.5%',
          fontSize: isMobile ? '0.8em' : '1em',
          borderRadius: '3rem',
          '&:hover': {
            backgroundColor: '#85B038',
          },
          mt: 4,
        }}
        onClick={handleGenerateVideoClick}
      >
        Generate Video
      </Button>
    </Box>
  );
};

export default HeroSection;

